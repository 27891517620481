import post from "./img/post.png";
import logo from "./img/logo.png";
import logofooter from "./img/logo-footer.png";
import tokenomics from "./img/tokenomics.png";
import t1 from "./img/symbol.png";
import t2 from "./img/tax.png";
import t3 from "./img/liquidity.png";
import tg from "./img/tg.png";
import tw from "./img/tw.png";
import dc from "./img/discord.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="bg-header py-4 text-white text-2xl">
        <div className="container mx-auto px-2 lg:px-5 flex gap-5 justify-between">
          <span>Quasi Inu</span>
          <span>Quasi Inu</span>
          <span>Quasi Inu</span>
          <span>Quasi Inu</span>
          <span>Quasi Inu</span>
          <span>Quasi Inu</span>
          <span>Quasi Inu</span>
          <span>Quasi Inu</span>
        </div>
      </header>

      <main>
        <section className="bg-[url('./img/bg-1.png')] bg-center bg-cover bg-no-repeat mt-2 py-20 min-h-[1000px] lg:min-h-[1300px] relative">
          <div className="max-w-3xl min-w-[350px] absolute bottom-24 left-1/2 -translate-x-1/2">
            <img src={post} alt="" />
          </div>
          <div className="max-w-[200px] absolute right-10 bottom-1/4 lg:top-20">
            <img src={logo} alt="" />
          </div>
          <div className="container mx-auto px-2 lg:px-5 relative">
            <ul className="flex justify-center gap-10 items-center text-4xl">
              <li>
                <a href="/" className="link transition-all hover:opacity-75">
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link transition-all hover:opacity-75"
                >
                  Chart
                </a>
              </li>
              <li>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link transition-all hover:opacity-75"
                >
                  Buy
                </a>
              </li>
            </ul>
            <p className="max-w-4xl mx-auto text-center text-white text-2xl mt-8">
              Meet Quasi, the beloved Pitbull of the Avalanche office! Quasi's
              charming presence has made them the heart and soul of our
              workspace. Now, you can show your love for Quasi by holding
              $QUASIINU tokens. Your support not only celebrates our favorite
              office companion but also contributes to the vibrant community
              around Quasi. Join the Quasi Inu movement and let's make every wag
              of the tail count!
            </p>
          </div>
        </section>

        <section className="bg-[url('./img/bg-2.png')] bg-cover bg-no-repeat py-20 relative">
          <div className="max-w-xl border-b-[2px] border-white pb-8">
            <div className="ml-auto max-w-xs">
              <img src={tokenomics} alt="" />
            </div>
          </div>
          <div className="container mx-auto px-2 lg:px-5 grid grid-cols-3 gap-3  lg:gap-24 mt-10">
            <div className="flip-animation">
              <img src={t1} alt="" />
            </div>
            <div className="flip-animation">
              <img src={t2} alt="" />
            </div>
            <div className="flip-animation">
              <img src={t3} alt="" />
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-[url('./img/bg-footer.png')] bg-cover bg-no-repeat pt-20 relative">
        <div className="container mx-auto px-2 lg:px-5">
          <ul className="flex justify-center gap-10">
            <li>
              <a
                className="w-20 block transition-all hover:opacity-75"
                target="_blank"
                href="https://t.me/QuasiInu"
                rel="noopener noreferrer"
              >
                <img src={tg} alt="" />
              </a>
            </li>
            <li>
              <a
                className="w-20 block transition-all hover:opacity-75"
                target="_blank"
                href="https://twitter.com/QuasiInu"
                rel="noopener noreferrer"
              >
                <img src={tw} alt="" />
              </a>
            </li>
            <li>
              <a
                className="w-20 block transition-all hover:opacity-75"
                target="_blank"
                href="#"
                rel="noopener noreferrer"
              >
                <img src={dc} alt="" />
              </a>
            </li>
          </ul>

          <div className="w-full lg:w-[80%] max-w-4xl mt-12 mx-auto bg-box px-5 lg:py-8 py-4 text-center text-white">
            <div className="w-24 lg:w-28 mx-auto">
              <img src={logofooter} alt="" />
            </div>
            <span className="block mt-5">
              @ 2023 Quasi Inu, All Rights Reserved
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
